import React from "react";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import Hero from "common/hero";
import CategoryDetails from "common/category-details";
import AccordionContent from "common/accordion";
import { TypeA } from "common/callouts";
import heroImageRef from "images/sun/impactsofsunlight/sun-impact-hero.webp";

import img1 from "images/sun/impactsofsunlight/impacts-of-sun-front.webp";

import img3 from "images/callouts/resources2x.webp";
import img4 from "images/callouts/cout-footer-daily-skin.webp";
import img5 from "images/callouts/cout-footer-journal.webp";

import imgChart1 from "images/sun/impactsofsunlight/chart-eucerin-sun-scale.webp";
import imgChart2 from "images/sun/sunproducts/oilcontrol-research-results.webp";

import "./impactsofsunlight.scss";

const MyHero = () => <Hero className="inner-hero" bgImagePath={heroImageRef} />;

function IndexPage({ pageContext }) {
  return (
    <Layout
      pageClass="page-sun-impactsofsunlight page-level-3"
      breadcrumbData={pageContext.breadcrumb}
      hero={<MyHero />}
    >
      <Seo
        pageTitle="Impacts of Sunlight"
        pageDescription="Learn about how to prevent the impacts of sunlight for your patients"
      />
      <div className="inner-body-content">
        <div className="top-content-level-3">
          <section className="inner-centered-container">
            <CategoryDetails
              headBgColor="#6ACAD5"
              headText="Impacts of Sunlight"
              infoText="Current gaps in sun protection<sup>1</sup>"
              bodyText="Photoprotection strategies and educational opportunities continue to evolve, especially for FST <nobr>IV-VI.</nobr>"
              productInfoImg={img1}
              productInfoImgAlt="Girls in broad sunlight"
              className="impacts-of-sunlight-div"
            >
              <p className="font-semi-bold teal-text">
                FST <nobr>IV-VI</nobr>
              </p>
              <p>
                Among healthcare professionals and patients, there are
                misconceptions around the need for photoprotection for FST{" "}
                <nobr>IV-VI</nobr>.
              </p>

              <p className="font-semi-bold teal-text">UVA/UVB</p>
              <p>
                Current research indicates that UVA/UVB protection alone is not
                sufficient for overall skin health, especially in FST{" "}
                <nobr>IV-VI</nobr>.
              </p>
              <p className="font-semi-bold teal-text">ROS/RNS</p>
              <p>
                UV and VL generate reactive species (ROS, RNS) that may
                contribute to skin damage and skin hyperpigmentation.{" "}
              </p>
              <p className="font-semi-bold teal-text">UV/VL</p>
              <p>
                For patients of all skin tones, there are unmet needs in
                photoprotection products regarding damage from free radicals
                induced by both UV <nobr>and VL.</nobr>
              </p>
              <br />
              <a
                className="button btn-spacer"
                href="https://www.jaad.org/issue/S0190-9622(22)X0002-9"
                target="_blank"
                rel="noopener noreferrer"
              >
                VIEW JOURNAL ARTICLES
              </a>
            </CategoryDetails>
            <Row>
              <Col xs={12}>
                <p className="acronyms first">
                  FST=Fitzpatrick skin type; RNS=reactive nitrogen species;
                  ROS=reactive oxygen species; VL=visible light.
                </p>
                <p className="references last">
                  <span className="font-bold">Reference: 1.</span> Rigel DS,
                  Taylor S, Lim H, et al. Photoprotection for skin of all color:
                  consensus and clinical guidance from an expert panel.{" "}
                  <em>J Am Acad Dermatol</em>. 2022;86(3):S1-S8.
                </p>
              </Col>
            </Row>
          </section>
        </div>
        <div className="gradient-bg">
          <section className="inner-centered-container">
            <AccordionContent
              accordionHeading="UVA1/Visible light"
              id="UVA1Visiblelight"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={imgChart1}
                    className="accordion-chart"
                    alt="Education regarding synergistic effects of UVA! and VL on skin"
                  />
                  <p className="references">
                    <span className="font-bold">Reference: 2.</span> Rigel D,
                    Lim H, Draelos Z, Weber T, Taylor S. Photoprotection for
                    all: current gaps and opportunities.{" "}
                    <em>J Am Acad Dermatol</em>. 2022;86(3):S18-S26.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Are your patients educated about <nobr>UVA1 and</nobr> VL?
                  </div>
                </Col>
              </Row>
            </AccordionContent>

            {/*<AccordionContent
              accordionHeading="RESEARCH RESULTS"
              id="researchresults"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={imgChart2}
                    className="accordion-chart"
                    alt="Assesment of product efficacy form UVA! and VL exposed skin"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> 10 subjects
                    with FST <nobr>IV-VI</nobr> enrolled (9 completed the
                    study), with exposure of 380 J/cm<sup>2</sup> of 2% UVA1 and
                    visible light with pretreatment 24 hours prior to exposure
                    and product reapplication 20 minutes prior to exposure.
                    Assessments conducted immediately, 24 hours, and 7 days
                    after exposure by diffuse reflectance spectroscopy,
                    cross-polarized images, and expert grading.
                  </p>
                  <p className="references">
                    <span className="font-bold">Reference: 3.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Provides sun protection on parity with a tinted mineral
                    sunscreen with iron oxide<sup>3</sup>
                  </div>
                </Col>
              </Row>
            </AccordionContent>*/}
          </section>
        </div>

        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img3}
                  imgAlt="Resources for sun care"
                  caption="Find resources to support your
                    sun care recommendations"
                  buttonText="Browse Resources"
                  buttonUrl="/resources"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img4}
                  imgAlt="Explore Body Care"
                  caption="Clinically proven therapeutic
                    solutions for daily skin care"
                  buttonText="Explore Body Care"
                  buttonUrl="/body"
                  imgStyles={{ height: 165 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img5}
                  imgAlt="View JAAD Articles"
                  caption="Discover how perspectives in sun protection are evolving"
                  buttonText="VIEW JOURNAL ARTICLES"
                  buttonUrl="https://www.jaad.org/issue/S0190-9622(22)X0002-9"
                  imgStyles={{ height: 165 }}
                  isExternalLink={true}
                  targetOpen="_blank"
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default IndexPage;
